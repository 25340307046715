import { BookingSubmissionType } from "../redux-slices/price-assistant/BookingSubmissionDataType";

export const submitBookingRequest = async (
  bookingData: BookingSubmissionType
): Promise<Response> => {
  const response = await fetch(
    "https://2jks0z3t5c.execute-api.us-west-2.amazonaws.com/default/submitBookingRequest",
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(bookingData),
    }
  );
  return response;
};
