import PriceCalc_Body_0 from './body-components/PriceCalc_Body_0';
import PriceCalc_Footer_2 from "./footer-components/PriceCalc_Footer_2";
import PriceCalc_Body_2 from "./body-components/PriceCalc_Body_2";
import PriceCalc_Body_3 from "./body-components/PriceCalc_Body_3";
import PriceCalc_Footer_1 from './footer-components/PriceCalc_Footer_1';
import PriceCalc_Body_1 from './body-components/PriceCalc_Body_1';
import PriceCalc_Footer_0 from './footer-components/PriceCalc_Footer_0';
import PriceCalc_Footer_3 from './footer-components/PriceCalc_Footer_3';
import PriceCalc_Body_5 from './body-components/PriceCalc_Body_5';
import PriceCalc_Footer_5 from './footer-components/PriceCalc_Footer_5';
import PriceCalc_Body_6 from './body-components/PriceCalc_Body_6';
import PriceCalc_Footer_6 from './footer-components/PriceCalc_Footer_6';
import PriceCalc_Body_7 from './body-components/PriceCalc_Body_7';
import PriceCalc_Footer_7 from './footer-components/PriceCalc_Footer_7';
import PriceCalc_Body_8 from './body-components/PriceCalc_Body_8';
import PriceCalc_Footer_8 from './footer-components/PriceCalc_Footer_8';
import PriceCalc_Body_9 from './body-components/PriceCalc_Body_9';
import PriceCalc_Footer_9 from './footer-components/PriceCalc_Footer_9';
import PriceCalc_Body_10 from './body-components/PriceCalc_Body_10';
import PriceCalc_Footer_10 from './footer-components/PriceCalc_Footer_10';
import PriceCalc_Body_4b from './body-components/PriceCalc_Body_4b';
import PriceCalc_Footer_4b from './footer-components/PriceCalc_Footer_4b';
import PriceCalc_Body_4a from './body-components/PriceCalc_Body_4a';
import PriceCalc_Footer_4a from './footer-components/PriceCalc_Footer_4a';
import PriceCalc_Body_4c from './body-components/PriceCalc_Body_4c';
import PriceCalc_Footer_4c from './footer-components/PriceCalc_Footer_4c';
import PriceCalc_Body_11 from './body-components/PriceCalc_Body_11';
import PriceCalc_Footer_11 from './footer-components/PriceCalc_Footer_11';
import PriceCalc_Body_12 from './body-components/PriceCalc_Body_12';
import PriceCalc_Footer_12 from './footer-components/PriceCalc_Footer_12';
import { PriceCalculatorDictionaryType } from './PriceCalculatorDictionaryType';
/* eslint-disable react/jsx-pascal-case */


export const PriceCalculatorDictionary: PriceCalculatorDictionaryType = {
    0: {
      headerText: "Instant Quote, No Waiting!",
      icon: null,
      bodyComponent: <PriceCalc_Body_0 />,
      footerComponent: <PriceCalc_Footer_0 />,
      activeImage: "mely-maid-logo"
    },
    1: {
      headerText: "Select Your Cleaning Service",
      icon: null,
      bodyComponent: <PriceCalc_Body_1 />,
      footerComponent: <PriceCalc_Footer_1 />,
      activeImage: "home-or-office"
    },
    2: {
      headerText: "Number of Bedrooms",
      icon: "noto:bed",
      bodyComponent: <PriceCalc_Body_2 />,
      footerComponent: <PriceCalc_Footer_2 />,
      activeImage: "num-bedrooms"
    },

    3: {
      
      headerText: "Number of Bathrooms",
      icon: "noto:bathtub",
      bodyComponent: <PriceCalc_Body_3 />,
      footerComponent: <PriceCalc_Footer_3 />,
      activeImage: "num-bathrooms"
    },
    4: {
      headerText: "Deep Cleaning Add-on",
      icon: null,
      bodyComponent: <PriceCalc_Body_4a />,
      footerComponent: <PriceCalc_Footer_4a />,
      activeImage: "deep-clean"
    },
    5: {
      headerText: "Window Cleaning Add-on",
      icon: null,
      bodyComponent: <PriceCalc_Body_4b />,
      footerComponent: <PriceCalc_Footer_4b />,
      activeImage: "window-cleaning"
    },
    6: {
      headerText: "Service Frequency",
      icon: null,
      bodyComponent: <PriceCalc_Body_4c />,
      footerComponent: <PriceCalc_Footer_4c />,
      activeImage: "service-frequency"
    },
    7: {
      headerText: "Calculating Price Estimate...",
      icon: null,
      bodyComponent: <PriceCalc_Body_5 />,
      footerComponent: <PriceCalc_Footer_5 />,
      activeImage: "mely-maid-logo"
    },
    8: {
      headerText: "Your Personalized Quote is Ready!",
      icon: null,
      bodyComponent: <PriceCalc_Body_6 />,
      footerComponent: <PriceCalc_Footer_6 />,
      activeImage: "mely-maid-logo"
    },
    9: {
      headerText: "Contact Info - Name",
      icon: null,
      bodyComponent: <PriceCalc_Body_7 />,
      footerComponent: <PriceCalc_Footer_7 />,
      activeImage: "mely-maid-logo"
    },
    10: {
      headerText: "Contact Info - Email",
      icon: null,
      bodyComponent: <PriceCalc_Body_8 />,
      footerComponent: <PriceCalc_Footer_8 />,
      activeImage: "mely-maid-logo"
    },
    11: {
      headerText: "Contact Info - Phone",
      icon: null,
      bodyComponent: <PriceCalc_Body_9 />,
      footerComponent: <PriceCalc_Footer_9 />,
      activeImage: "mely-maid-logo"
    },
    12: {
      headerText: "Contact Info - Zip Code",
      icon: null,
      bodyComponent: <PriceCalc_Body_10 />,
      footerComponent: <PriceCalc_Footer_10 />,
      activeImage: "mely-maid-logo"
    },
    13: {
      headerText: "Any Comments or Requests?",
      icon: null,
      bodyComponent: <PriceCalc_Body_11 />,
      footerComponent: <PriceCalc_Footer_11 />,
      activeImage: "mely-maid-logo"
    },
    14: {
      headerText: "Booking Request Submitted!",
      icon: null,
      bodyComponent: <PriceCalc_Body_12 />,
      footerComponent: <PriceCalc_Footer_12 />,
      activeImage: "mely-maid-logo"
    },
  };
