import React from 'react';
import AllRoute from '../router'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Provider } from 'react-redux';
import store from '../../redux-slices/store';
import TixaeChatBot from '../../components/ai-chatbot/TixaeChatBot';

const App = () => {

  return (
    <Provider store={store}>

      <div className="App" id='scrool'>
        <AllRoute />
        <ToastContainer />
        <TixaeChatBot/>
      </div>
    </Provider>
  );
}

export default App;