import { useEffect, useState } from "react";

const useLoadingText = (isSubmitting: boolean): string => {
  const [loadingText, setLoadingText] = useState("Submitting");

  useEffect(() => {
    if (isSubmitting) {
      const loadingIntervals = [
        "Submitting",
        "Submitting .",
        "Submitting ..",
        "Submitting ..."
      ];
      let currentIndex = 0;
      const interval = setInterval(() => {
        currentIndex = (currentIndex + 1) % loadingIntervals.length;
        setLoadingText(loadingIntervals[currentIndex]);
      }, 500);

      return () => clearInterval(interval);
    } else {
      setLoadingText("Submitting");
    }
  }, [isSubmitting]);

  return loadingText;
};

export default useLoadingText;
