/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-pascal-case */
// @ts-ignore
import MelyMaidLogo from "../../images/mely-maid-logo.jpg";
// @ts-ignore
import HomeOrOfficeImg from "../../images/booking/home-or-office.jpg";

// @ts-ignore
import NumBedroomsImg from "../../images/booking/num-bedrooms.jpg";

// @ts-ignore
import NumBathroomsImg from "../../images/booking/num-bathrooms.jpg";

// @ts-ignore
import DeepCleanImg from "../../images/booking/deep-clean.jpg";

// @ts-ignore
import WindowCleaningImg from "../../images/booking/window-cleaning.jpg";
// @ts-ignore
import ServiceFrequencyImg from "../../images/booking/service-frequency.jpg";


import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../redux-slices/hooks";
import {
  priceAssisstant_ResetData,
  selectCurrentPriceAssistantPage,
  setAssistantVariant,
  setCurrentPriceAssistantPage,
} from "../../redux-slices/price-assistant/PriceAssistantSlice";

import "./PriceCalculatorWidget.scss";
import PriceCalc_Body from "./body-components/PriceCalc_Body";
import PriceCalc_Footer from "./footer-components/PriceCalc_Footer";
import PriceCalc_Header from "./header-components/PriceCalc_Header";
import { PriceCalculatorDictionary } from "./PriceCalculatorDictionary";
import { ActiveImageType } from "../../redux-slices/price-assistant/ActiveImageType";

interface Props {
  variant: "Price" | "Booking";
}

const PriceCalculatorWidget: React.FC<Props> = ({ variant = "Price" }) => {
  const pageNumber = useAppSelector(selectCurrentPriceAssistantPage);
  const [activeImage, setActiveImage] =
    useState<ActiveImageType>("mely-maid-logo");

  const [activeImageSrc, setActiveImageSrc] = useState(MelyMaidLogo);

  useEffect(() => {
    switch (activeImage) {
      case "mely-maid-logo":
        setActiveImageSrc(MelyMaidLogo);
        break;
      case "home-or-office":
        setActiveImageSrc(HomeOrOfficeImg);
        break;
      case "num-bedrooms":
        setActiveImageSrc(NumBedroomsImg);
        break;
      case "num-bathrooms":
        setActiveImageSrc(NumBathroomsImg);
        break;
      case "deep-clean":
        setActiveImageSrc(DeepCleanImg);
        break;
      case "window-cleaning":
        setActiveImageSrc(WindowCleaningImg);
        break;
        case "service-frequency":
        setActiveImageSrc(ServiceFrequencyImg);
        break;
      default:
        setActiveImageSrc(MelyMaidLogo);
    }
  }, [activeImage]);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (variant === "Booking") {
      dispatch(setAssistantVariant("Booking"));
    } else {
      dispatch(setAssistantVariant("Price"));
    }
  }, [variant]);

  useEffect(() => {
    dispatch(priceAssisstant_ResetData());
  }, []);

  useEffect(() => {
    if (!(pageNumber.toString() in PriceCalculatorDictionary)) {
      dispatch(setCurrentPriceAssistantPage(0));
    }

    setActiveImage(PriceCalculatorDictionary[pageNumber]?.activeImage);
  }, [pageNumber]);

  const EmailButton = () => {
    const email = "contact@melymaid.com";
    const subject = "Mely Maid Service Inquiry";
    const body = "Hello! I would like to inquire about";

    const href = `mailto:${email}?subject=${encodeURIComponent(
      subject
    )}&body=${encodeURIComponent(body)}`;
    return (
      <a className="text_phone_number" href={href}>
        contact@melymaid.com
      </a>
    );
  };

  return (
    <>
      <div className="price_calc_container">
        <div className="price_calc">
          <div className="price_calc_left">
            <img
              height={410}
              width={410}
              src={activeImageSrc}
              alt="Mely Maid Logo"
            />
          </div>

          <div className="price_calc_right">
            <PriceCalc_Header
              pageNumber={pageNumber}
              activeImageSrc={activeImageSrc}
            />
            <PriceCalc_Body pageNumber={pageNumber} />
            <PriceCalc_Footer pageNumber={pageNumber} />
          </div>
        </div>
      </div>

      <section className="contact-info-section section-padding">
        <div className="container">
          <div className="row">
            <div className="col col-xs-12">
              <div className="contact-info clearfix">
                <div>
                  <div className="icon">
                    <i className="ti-email"></i>
                  </div>
                  <h5>Email</h5>
                  <EmailButton />
                </div>
                <div>
                  <div className="icon">
                    <i className="ti-headphone-alt"></i>
                  </div>
                  <h5>Phone</h5>
                  <a className="text_phone_number" href="tel:+19496499250">
                    949-649-9250
                  </a>
                </div>
                <div>
                  <div className="icon">
                    <i className="ti-location-pin"></i>
                  </div>
                  <h5>Location</h5>
                  <p>Orange County, California, USA</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default PriceCalculatorWidget;
