import React from 'react';
import ContactForm from '../ContactFrom/ContactForm'


const Contactpage = () => {

    
    const EmailButton = () => {

        const email = 'contact@melymaid.com';
        const subject = 'Mely Maid Service Inquiry';
        const body = 'Hello! I would like to inquire about';

        const href = `mailto:${email}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
        return (
            <a
                className='text_phone_number'
                href={href}>contact@melymaid.com</a>
        );
    }

    return (
        <div className="contact-page-wrap">
            {/* <section className="contact-section contact-section-s2 section-padding">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col col-xl-6  col-lg-8 col-md-10 col-12">
                            <div className="section-title">
                                <h2>Ask us anything!</h2>
                            </div>
                        </div>
                    </div>

                    <div className="row justify-content-center">
                        <div className="col col-lg-8">
                            <div className="content-area">
                                <p>We're happy to answer any questions you have regarding our services!</p>
                                <div className="contact-form">
                                    <ContactForm />
                                </div> 
                            </div>
                        </div>
                    </div>
                </div>
            </section>  */}
            <section className="contact-info-section section-padding">
                <div className="container">
                    <div className="row">
                        <div className="col col-xs-12">
                            <div className="contact-info clearfix">
                         
                                <div>
                                    <div className="icon">
                                        <i className="ti-headphone-alt"></i>
                                    </div>
                                    <h5>Phone</h5>
                                    <a 
                                className='text_phone_number'
                                href="tel:+19496499250">949-649-9250</a>
                                </div>
                                <div>
                                    <div className="icon">
                                        <i className="ti-location-pin"></i>
                                    </div>
                                    <h5>Location</h5>
                                    <p>Orange County, California, USA</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="contact-map-section">
                <div className="contact-map">
                <iframe title='contact-map' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d106088.32060877653!2d-117.92942611434226!3d33.71747080579606!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80dcdebaa27e439d%3A0x8c0e62b0f038f176!2sOrange+County%2C+CA%2C+USA!5e0!3m2!1sen!2sbd!4vXXXXXXXXXXXXX" allowFullScreen></iframe>
                </div>
            </section>
        </div>
    )

}

export default Contactpage;
