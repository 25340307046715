import React, { useEffect } from 'react';

const TixaeChatBot = () => {
  useEffect(() => {
    // Set up the configuration for the chatbot
    window.VG_CONFIG = {
      ID: "bqm453rfipaagt8c", // YOUR AGENT ID
      region: 'na',         // YOUR ACCOUNT REGION
      render: 'bottom-right', // Options: 'full-width', 'bottom-left', 'bottom-right'
      stylesheets: [
        // Base TIXAE Agents CSS
        "https://vg-bunny-cdn.b-cdn.net/vg_live_build/styles.css",
        // You can add additional custom stylesheets here
      ],
      // Optional user data (currently commented out)
      // user: {
      //   name: 'John Doe',
      //   email: 'johndoe@gmail.com',
      //   phone: '+1234567890',
      // },
      // Optional: use your own user ID
      // userID: 'USER_ID',
      // Optional: whether to autostart the chatbot with a proactive message
      // autostart: true,
    };

    // Create the script element to load the chatbot bundle
    const vgScript = document.createElement("script");
    vgScript.src = "https://vg-bunny-cdn.b-cdn.net/vg_live_build/vg_bundle.js";
    vgScript.defer = true; // Remove defer if you want the widget to load faster (may affect site performance)
    
    // Append the script to the body
    document.body.appendChild(vgScript);
    
    // Optionally, clean up the script on unmount if needed
    return () => {
      document.body.removeChild(vgScript);
    };
  }, []);

  return (
    <>
      {/* This container is where the widget will render */}
      <div style={{ width: 0, height: 0 }} id="VG_OVERLAY_CONTAINER" />
    </>
  );
};

export default TixaeChatBot;
