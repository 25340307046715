import React, { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../redux-slices/hooks";
import "../PriceCalculatorWidget.scss";
import PriceCalc_RectangleButton from "./PriceCalc_RectangleButton";
import {
  priceAssisstant_NextPage,
  priceAssisstant_PreviousPage,
  selectBookingContactInfo,
  selectCleaningService,
  selectNumberBathrooms,
  selectNumberBedrooms,
  selectPriceEstimate,
  selectServiceAddOns,
  selectServiceFrequency,
} from "../../../redux-slices/price-assistant/PriceAssistantSlice";
import { BookingSubmissionType } from "../../../redux-slices/price-assistant/BookingSubmissionDataType";
import { submitBookingRequest } from "../../../services/bookingService";
import useLoadingText from "../../../hooks/useLoadingText";

const PriceCalc_Footer_11: React.FC = () => {
  const dispatch = useAppDispatch();

  const bookingContactInfo = useAppSelector(selectBookingContactInfo);
  const cleaningService = useAppSelector(selectCleaningService);
  const numberBedrooms = useAppSelector(selectNumberBedrooms);
  const numberBathrooms = useAppSelector(selectNumberBathrooms);
  const serviceFrequency = useAppSelector(selectServiceFrequency);
  const serviceAddOns = useAppSelector(selectServiceAddOns);
  const priceEstimate = useAppSelector(selectPriceEstimate);

  const [submitting, setSubmitting] = useState(false);
  const loadingText = useLoadingText(submitting);

  const prevPage = () => {
    dispatch(priceAssisstant_PreviousPage());
  };

  const submitHandler = async () => {
    setSubmitting(true);

    const formData: BookingSubmissionType = {
      fullName: bookingContactInfo.fullName,
      email: bookingContactInfo.email,
      phoneNumber: bookingContactInfo.phoneNumber,
      street: bookingContactInfo.street,
      city: bookingContactInfo.city,
      state: bookingContactInfo.state,
      zip: bookingContactInfo.zip,
      comments: bookingContactInfo.comments,
      cleaningService,
      numberBedrooms,
      numberBathrooms,
      serviceFrequency,
      deepCleanAddOnEnabled: serviceAddOns.deepClean,
      windowCleaningAddOnEnabled: serviceAddOns.windowCleaning,
      priceEstimate,
    };

    try {
      const response = await submitBookingRequest(formData);
      if (response.ok) {
        dispatch(priceAssisstant_NextPage());
      } else {
        alert("Failed to submit the form. Please try again.");
      }
    } catch (error) {
      alert("An error occurred while submitting the form.");
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <div className="price_calc_footer_final">
      {submitting && (
        <div className="price_calc_footer_final_loading">{loadingText}</div>
      )}
      <PriceCalc_RectangleButton
        enabled={!submitting}
        label="Back"
        handleClick={prevPage}
      />
      <PriceCalc_RectangleButton
        enabled={!submitting}
        type="submit"
        label="Submit"
        handleClick={submitHandler}
      />
    </div>
  );
};

export default PriceCalc_Footer_11;
