/* eslint-disable react/jsx-pascal-case */
// @ts-ignore
import MelyMaidLogo from "../../../images/mely-maid-logo.jpg";
import { useEffect, useState } from "react";
import "../PriceCalculatorWidget.scss";
import { PriceCalculatorDictionary } from "../PriceCalculatorDictionary";
import { useAppSelector } from "../../../redux-slices/hooks";
import { selectAssistantVariant } from "../../../redux-slices/price-assistant/PriceAssistantSlice";

interface Props {
  pageNumber: number;
  activeImageSrc: string;
}

const PriceCalc_Header: React.FC<Props> = ({ 
  pageNumber = 0,
  activeImageSrc
 }) => {
  const [headerText, setHeaderText] = useState("Instant Quote, No Waiting!");
  const [showLogo, setShowLogo] = useState(true);
  const assistantVariant = useAppSelector(selectAssistantVariant);

  useEffect(() => {
    setHeaderText(
      PriceCalculatorDictionary[pageNumber]?.headerText ||
        PriceCalculatorDictionary[0].headerText
    );

    if (pageNumber === 8) {
      setShowLogo(false);
    } else {
      setShowLogo(true);
    }

    if (pageNumber === 0 && assistantVariant === "Booking") {
      setHeaderText("Mely Maid Booking Assistant");
    } 
  }, [pageNumber, assistantVariant]);

  return (
    <>
      <div className="price_calc_right_header">
        <h2>{headerText}</h2>
      </div>

      {showLogo && (
        <div className="price_calc_right_img">
          <img
            height={220}
            width={220}
            src={activeImageSrc}
            alt="Mely Maid Logo"
          />
        </div>
      )}
    </>
  );
};

export default PriceCalc_Header;
